import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataTableFilter, DataTableObject, NotificationObject } from '../shared.model';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  /**
   * Public variables
   */
  notificationList: Array<NotificationObject> = new Array<NotificationObject>();
  is_loading_results: boolean = false;
  filter: DataTableFilter = new DataTableFilter();


  /**
   * Private variables
   */
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(private _sharedService: SharedService,
              private _cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getNotifications();
  }

  /**
   * Function to GET Notifications
   */
   getNotifications() {
    this.is_loading_results = true;

    this.filter.pageSize = 10;
    this.filter.sortColumn = 'dateCreated';
    this.filter.sortDirection = 'desc';

    this._sharedService.getNotifications(this.filter).pipe(takeUntil(this._unsubscribeAll)).subscribe(
      (result: DataTableObject<Array<NotificationObject>>) => {
        this.notificationList = this.notificationList.concat(result.data);
        console.log('notifications --> ', result);
        this._sharedService.notificationList.next(this.notificationList);
        this.is_loading_results = false;
      }
    )
  }

  showTime(time: any) {
    return moment(time).fromNow();
  }

  onScrollDown() {
    this.filter.pageNumber += 1;
    this.getNotifications();
    this._cdRef.detectChanges();
  }

}
