import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastRef } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss']
})
export class CustomToastComponent implements OnInit {

  /**
   * Public Variables
   */
   data: any;
   toastId: number;
   toastRef: ToastRef<any>;
   is_saving_results: boolean = false;

   /**
     * Private Variables
     */
   private _unsubscribeAll: Subject<any> = new Subject<any>();

   constructor(private _notificationService: NotificationsService,
               private _matDialog: MatDialog) { }

   ngOnInit(): void {
     console.log('data --> ', this.data)
   }

   closeToast() {
    this.toastRef.close();
  }

}
