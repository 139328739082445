import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { ILogger, LogLevel } from '@microsoft/signalr';
import { Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthLocalService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  /**
   * Public Variables
   */
  onNotifiationReceived: Subject<string> = new Subject();

  /**
   * Private Variables
   */
  private _api_url = environment.API_URL;
  private _http_header: HttpHeaders = this._authService.tokenHeader();
  private _hub_connection: signalR.HubConnection;

  constructor(private _httpClient: HttpClient,
              private _authService: AuthLocalService) { }

  // -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
	// @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
    * Function for Joining Group
    */
   private _joinGroup(): void {
    this._hub_connection.invoke('JoinGroup', 'administrator').then(() => {
      console.log(' joined group -> ', this._hub_connection);
    })
        .catch((err) => console.error('JOINING GROUP ERROR -> ' + err.toString())
    );
  }

  /**
   * Function for handling errors
   *
   * @param { HttpErrorResponse } error
   *
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }



  // -----------------------------------------------------------------------------------------------------
	// @ SignalR
	// -----------------------------------------------------------------------------------------------------

  /**
   * Function to Setup SignalR
   */
  async SetupSignalr(): Promise<void> {
    this._hub_connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withAutomaticReconnect([0, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000, 3000, 1000, 2000])
      .withUrl(this._api_url + 'hub/notifications', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();

    await this._hub_connection.start();

    if(this._authService.currentActiveAccount.memberType != 5) {
      this._joinGroup();
    }

    this._hub_connection.on('BroadcastTaskHistoryMessage',
      (data: any) => {
        this.onNotifiationReceived.next(data);
      }
    );
  }
}

export class MyLogger implements ILogger {
  log(logLevel: LogLevel, message: string) {
    console.log('logLevel -> ',logLevel)
    console.log('message -> ',message)
      // Use `message` and `logLevel` to record the log message to your own system
  }
}
