export class APIResponse<T> {
    constructor(
      public isError: boolean = false,
      public message: string | null = null,
      public response: T,
      public statusCode: number = 0
    ){}
}

export class APIResult<T> {
    constructor(
      public isError: boolean = false,
      public message: string | null = null,
      public response: T,
      public result: T,
      public statusCode: number = 0
    ){}
}

export class DataTableFilter {
    constructor(
        public  pageNumber: number = 1,
        public pageSize: number = 20,
        public sortColumn: string = 'firstName',
        public sortDirection: string = 'asc',
        public status: number | null = null,
        public orders: Array<number> | null = null,
        public employeeId: number | null = null,
        public positionId: number | null = null,
    ){}
}

export class DataTableObject<T> {
    constructor(
      public data: T,
      public pageNumber: number = 0,
      public pageSize: number = 0,
      public totalPages: number = 0,
      public totalRecords: number = 0,
    ){}
}

export class MediaModel {
  constructor(
    public name: string | null = null,
    public bytedata: string | null = null,
    public needresize: boolean | null = null,
    public blobname: string | null = null,
    public documentblob: string | null = null,
    public documenturl: string | null = null,
    public type: string | null = null,
    public resizewidth: number | null = null,
    public resizeheight: number | null = null
  ) { }
}

export class MediaImageObject {
  constructor(
    public documentname: string | null = null,
    public docurl: string | null = null,
    public resizedname: string | null = null,
    public resizedurl: string | null = null
  ){}
}

export class OneIdModel {
  constructor(
    public id: number | null | undefined = null
  ){}
}

export class NotificationObject {
  constructor(
    public notificationId: number | null = null,
    public taskHistoryId: number | null = null,
    public workerId: number | null = null,
    public workerName: string | null = null,
    public workerProfilePicture: string | null = null,
    public workPositionId: number | null = null,
    public workPositionName: string | null = null,
    public message: string | null = null,
    public dateCreated: string | Date | null = null
  ){}
}
