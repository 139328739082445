import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedService } from '../shared/shared.service';
import { AccesiblePages, LoginObject } from './auth.model';
import { AuthLocalService } from './auth.service';

@Injectable()
export class AuthGuard  {

  constructor(private _sharedService: SharedService,
              private router: Router,
              private _authService: AuthLocalService,) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree  {

    let token = this._authService.isTokenHeaderEmpty();
    if (!token) {
      let accountData = this._authService.currentActiveAccount;
      if (accountData.memberId) {
        return true
      }
      else {
        return this._authService.getUserAuthenticationData().pipe(
          map(
            (data: LoginObject) => {
                let page: AccesiblePages | null = data.accessiblePages.find((page: AccesiblePages) => state.url.includes('notifications') ? new AccesiblePages() : state.url.includes(page.pageUrl ? page.pageUrl : '') ? page : null)!;
    
                if(page) {
                    return true;
                }
                this.router.navigate(['auth']);
                return false;
            },
            (error: any) => {
              this.router.navigate(['auth']);
              return false;
            })
        );
      }
    } else {
      this.router.navigate(['auth']);
      return false
    }

  }

  canActivateChild(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
}
