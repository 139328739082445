import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SidenavItemsObject } from './app.model';
import { AppService } from './app.service';
import { AuthLocalService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  /**
    * Public variables
    */
  title = 'e-karta-app';
  sidenav_icons: Array<SidenavItemsObject> = new Array<SidenavItemsObject>(); 
  show_sidebar: boolean = false;

  /**
    * Private variables
    */
  private _unsubscribeAll: Subject<any> = new Subject();

  /**
    * Constructor
    * 
    * @param { AuthLocalService } _authService
    * @param { Router } _router
    *  
    */
  constructor(private _authService: AuthLocalService,
              private _router: Router,
              private _appService: AppService) {  }

  /**
    * On init
    */
  ngOnInit(){
    this._checkUser();
    this._checkToken();

    this._appService.closeSidenav.pipe(takeUntil(this._unsubscribeAll)).subscribe(
      data => {
        this.show_sidebar = false;
      }
    )
  }

  /**
    * On destroy
    */
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
    * Function for Checking User
    */
  private _checkUser(){
    this._appService.activeUserChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(
      data => {
        if (data && data.firstName != 'emptyuser') {
          this.show_sidebar = true;
        }
      }
    )
  }

  /**
    * Function for Checking Token
    */
  private _checkToken(){
    let is_token_empty = this._authService.isTokenHeaderEmpty();
    // if (is_token_empty) {
    //    this.show_sidebar = false;
    //    debugger;
    //    this._router.navigate(['auth']);      
    // } else {
    //    this._authService.getUserAuthenticationData().pipe(takeUntil(this._unsubscribeAll)).subscribe();
    //    this.show_sidebar = true;
    // }
  }



}