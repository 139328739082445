import { APIResult } from "../shared/shared.model";

export class Account {
    constructor(
        public key ?: string,
        public password ?: string,
    ) {}
}


export class Authenticate {
    constructor(
        public memberId ?: number,
        public token ?: string | null,
        public firstName ?: string | null,
        public lastName ?: string | null,
        public memberKey ?: string | null,
        public email ?: string | null,
        public memberPassword ?: string | null,
        public memberStatus?: number | null,
        public memberType ?: number | null,
        public dateCreated ?: string | null,
        public image ?: string | null,
    ){}
}

export class LoginObject {
    constructor(
        public loginToken: string | null = null,
        public member: Authenticate = new Authenticate(),
        public accessiblePages: Array<AccesiblePages> = new Array<AccesiblePages>()
    ){ }
}

export class AccesiblePages {
    constructor(
        public availableToAll: boolean | null = null,
        public dateCreated: string | null = null,
        public fkParentId: number | null = null,
        public forMemberType: number | null = null,
        public icon: string | null = null,
        public isSubmenu: boolean | null = null,
        public orderById: number | null = null,
        public pageId: number | null = null,
        public pageKey: string | null = null,
        public pageName: string | null = null,
        public pageUrl: string | null = null
    ){}
}
