<div class="sidenav ps-container">

    <div class="sidenav-brand-holder">
        <img src="../../../assets/images/tech-up-logo.jpg" class="sidenav-brand-holder">
    </div>

    <div class="sidenav-icons-holder ps">
        <div class="sidenav-icon-holder" 
            *ngFor="let sidenav_item of sidenav_items"
            [routerLink]="sidenav_item.pageUrl"
            routerLinkActive="selected-sidenav">
            <div class="sidenav-icon-txt-holder">
                <mat-icon class="sidenav-icon"> {{ sidenav_item.icon }} </mat-icon>
                <p class="sidenav-txt"> {{ sidenav_item.pageName }} </p>
            </div>
        </div>
    </div>
</div>
