import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookupService } from './look-up/look-up.service';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { SharedService } from './shared.service';
import { NotificationsComponent } from './notifications/notifications.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    SidenavComponent,
    NotificationsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    //PerfectScrollbarModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    InfiniteScrollModule
  ],
  exports: [
    SidenavComponent
  ],
  providers: [
    LookupService,
    SharedService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ]
})
export class SharedModule { }
