<div class="custom-spinner-wrapper" *ngIf="is_saving_results">
  <mat-spinner strokeWidth="1" [diameter]="65"></mat-spinner>
</div>

<div class="wrapper">
  <div class="">
    <p>Вработениот <b>{{ data.workerName }}</b> на работна позиција <b>{{ data.workPositionName }}</b> отиде на пауза поради следната причина:</p>
    <p>{{ data.message }}</p>
  </div>
  <div class="d-flex justify-content-end mt-2">
    <button class="btn btn-primary" (click)="closeToast()">ОК</button>
  </div>
</div>
