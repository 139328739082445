import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { Authenticate } from 'src/app/auth/auth.model';
import { AuthLocalService } from 'src/app/auth/auth.service';
import { CustomToastComponent } from 'src/app/notifications/custom-toast/custom-toast.component';
import { NotificationsService } from 'src/app/notifications/notifications.service';
import { DataTableFilter, DataTableObject, NotificationObject } from '../shared.model';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  is_loading_results: boolean = false;
  currentActiveAccount: Authenticate = new Authenticate();
  notificationList: Array<NotificationObject> = new Array<NotificationObject>();
  filter: DataTableFilter = new DataTableFilter();

  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private _appService: AppService,
    private _sharedService: SharedService,
    private _authService: AuthLocalService,
    private _notificationsService: NotificationsService,
    private _toastr: ToastrService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this._checkUser();
    this._notificationsService.SetupSignalr();
    this._subscribeToNotifications();
    this.getNotifications();
  }

  /**
    * Function for Checking User
    */
   private _checkUser(){
    this._appService.activeUserChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(
      data => {
        if (data && data.firstName != 'emptyuser') {
          this.currentActiveAccount = data;
          this.currentActiveAccount.image = data.image;
          console.log('user', this.currentActiveAccount)
        }
      }
    )
  }

  /**
   * Function to Subscribe to Notifications
   */
  private _subscribeToNotifications() {
    this._notificationsService.onNotifiationReceived.pipe(takeUntil(this._unsubscribeAll)).subscribe(
      (data: any) => {
      this.openToastNotification(data);
      }
    )
  }

  /**
   * Function to open General Reminder Notificaton Toast Component
   */
  openToastNotification(object: any) {
    const toast = this._toastr.info('General Reminder', object, {
      toastComponent: CustomToastComponent,
      closeButton: true,
      tapToDismiss: false,
      disableTimeOut: true,
    });
    console.log('general --> ', object)
    toast.toastRef.componentInstance.data = object;
    toast.toastRef.componentInstance.toastId = toast.toastId;
    toast.toastRef.componentInstance.toastRef = toast.toastRef;
  }

  /**
   * Function to open notifications
   */
   openNotifications() {
    this._router.navigate(['notifications']);

  }

  /**
   * Function to log out
   */
  logout() {
    this._authService.logOut();
  }

  /**
   * Function to GET Notifications
   */
  getNotifications() {
    this.is_loading_results = true;

    this.filter.pageNumber = 1;
    this.filter.pageSize = 5;
    this.filter.sortColumn = 'dateCreated';
    this.filter.sortDirection = 'desc';

    this._sharedService.getNotifications(this.filter).pipe(takeUntil(this._unsubscribeAll)).subscribe(
      (result: DataTableObject<Array<NotificationObject>>) => {
        this.notificationList = result.data;
        this._sharedService.notificationList.next(this.notificationList);
        this.is_loading_results = false;
      }
    )
  }

  showTime(time: any) {
    return moment(time).fromNow();
  }
}
