import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [CustomToastComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ]
})
export class NotificationsModule { }
