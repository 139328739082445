import { Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AccesiblePages, Authenticate } from 'src/app/auth/auth.model';
import { AuthLocalService } from 'src/app/auth/auth.service';
import { NotificationsService } from 'src/app/notifications/notifications.service';
import { SidenavItemsObject } from '../../app.model';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  /**
    * Public Variables
    */
  sidenav_items: Array<AccesiblePages> = new Array<AccesiblePages>();
  config: PerfectScrollbarConfigInterface = {};
  currentActiveAccount: Authenticate = new Authenticate();

  /**
    * Private variables
    */
  private _unsubscribeAll: Subject<any> = new Subject();

  /**
    * Constructor
    *
    * @param { SharedService } _sharedService
    * @param { AppService } _appService
    *
    */
  constructor(
    private _sharedService: SharedService,
    private _appService: AppService,
    private _authService: AuthLocalService,
    private _notificationsService: NotificationsService
    ) { }

  /**
    * On init
    */
  ngOnInit(): void {
    this._setSidenavItems();
    this._checkUser();
  }

  /**
    * Function for Checking User
    */
     private _checkUser(){
      this._appService.activeUserChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(
        data => {
          if (data && data.firstName != 'emptyuser') {
            this.currentActiveAccount = data;
          }
        }
      )
    }

  /**
    * On destroy
    */
   ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
    * Function for Setting Sidenav Items
    */
  private _setSidenavItems(){
    this._sharedService.sidenav_items.pipe(takeUntil(this._unsubscribeAll)).subscribe(
      (items: Array<AccesiblePages>) => {
        this.sidenav_items = items;
      }
    );
  }

  logout() {
    this._authService.logOut();
  }

}
