import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';
import { NotificationsComponent } from './shared/notifications/notifications.component';
const routes: Routes = [
  {
    path : '',
    redirectTo : 'auth',
    pathMatch : 'full'
  },
  {
    path : 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path : 'machines',
    loadChildren : () => import('./machines/machines.module').then(m => m.MachinesModule),
    canActivate: [AuthGuard]
  },
  {
    path : 'employees',
    loadChildren : () => import('./employees/employees.module').then(m => m.EmployeesModule),
    canActivate: [AuthGuard]
  },
  {
    path : 'work-process-scheme',
    loadChildren : () => import('./work-process-scheme/work-process-scheme.module').then(m => m.WorkProcessSchemeModule),
    canActivate: [AuthGuard]
  },
  {
    path : 'task-management',
    loadChildren : () => import('./task-management/task-management.module').then(m => m.TaskManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path : 'work-orders',
    loadChildren : () => import('./work-orders/work-orders.module').then(m => m.WorkOrdersModule),
    canActivate: [AuthGuard]
  },
  {
    path : 'work-positions',
    loadChildren : () => import('./work-positions/work-positions.module').then(m => m.WorkPositionsModule),
    canActivate: [AuthGuard]
  },
  {
    path : 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'file-manager',
    loadChildren: () => import('./file-manager/file-manager.module').then(m => m.FileManagerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path : '**',
    redirectTo : 'auth',
    pathMatch : 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
