import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccesiblePages } from '../auth/auth.model';
import { APIResponse, APIResult, DataTableFilter, DataTableObject, MediaImageObject, MediaModel, NotificationObject } from './shared.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

	/**
	  * Public Variables
	  */
	sidenav_items: BehaviorSubject<Array<AccesiblePages>> = new BehaviorSubject<Array<AccesiblePages>>(new Array<AccesiblePages>())
  notificationList: BehaviorSubject<Array<NotificationObject>> = new BehaviorSubject<Array<NotificationObject>>(new Array<NotificationObject>())

	/**
	  * Private Variables
	  */
  	private _api_url: string = environment.API_URL;

	/**
	  * Constructor
	  *
	  * @param { HttpClient } _httpClient
	  *
	  */
	constructor(private _httpClient: HttpClient) { }

	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------

  /**
	  * Function to handle error when the server return an error
	  *
	  * @param { HttpErrorResponse } error
	  *
	  */
	private _handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error("An error occurred:", error.error.message);
		} else {
			// The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` + `body was: ${error.error}`
			);
		}
		// return an observable with a user-facing error message
		return throwError(error);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
 	// -----------------------------------------------------------------------------------------------------

	/**
	  * Function to Upload Image and get URL
	  *
	  *  @param { MediaModel } form_data
	  *
	  */
	public mediaImage(form_data: FormData): Observable<string> {
		return this._httpClient.post<APIResult<string>>(this._api_url + 'media/image/upload', form_data).pipe(
			map((data: APIResult<string>) => data.response),
			catchError(this._handleError)
		);
	}


	/**
      * Function to Upload Video and get URL
	  *
	  * @param { FormData } form_data
	  *
      */
	public mediaVideo(form_data: FormData): Observable<any> {
		return this._httpClient.post<APIResult<string>>(this._api_url + 'media/video/upload', form_data).pipe(
			map((data: APIResult<string>) => data.response),
			catchError(this._handleError)
		);
	}


	/**
    * Function to Upload Document and get URL
	  *
	  *  @param { FormData } form_data
	  *
    */
    public mediaDocument(form_data: FormData): Observable<any> {
		return this._httpClient.post<APIResult<string>>(this._api_url + 'media/document/upload', form_data).pipe(
		  map((data: APIResult<string>) => data.response),
		  catchError(this._handleError)
		);
	}

  /**
   * Function to GET Notifications
   */
  public getNotifications(filter : DataTableFilter): Observable<DataTableObject<Array<NotificationObject>>> {
    return this._httpClient.post<APIResult<DataTableObject<Array<NotificationObject>>>>(this._api_url + 'notifications/list', filter).pipe(
      map((data : APIResult<DataTableObject<Array<NotificationObject>>>) => {
      return data.response
    }),
    catchError(this._handleError)
  );
  }
}
