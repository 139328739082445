<div class="custom-spinner-wrapper" *ngIf="is_loading_results">
  <mat-spinner strokeWidth="1" [diameter]="65"></mat-spinner>
</div>

<div class="header-content d-flex justify-content-between align">
  <img src="../../../assets/images/FITR MKD Full - White png format.png" alt="">

  <div class="justify-content-between">
    <!-- <button class="btn btn-icon btn-primary mr-8"> -->
    <mat-icon class="text-primary mr-8 cursor-pointer" style="font-size: 24px !important;"
      [matMenuTriggerFor]="notification_menu">notifications</mat-icon>

    <mat-menu #notification_menu="matMenu" xPosition="before" class="pb-0">
      <div class="p-4">
        <div *ngFor="let notification of notificationList">
          <div class="d-flex justify-content-between my-4">
            <img style="width: 50px; height: 50px; border-radius: 50%;" [src]="notification.workerProfilePicture">
            <div class="d-flex flex-column ml-2">
              <span>Вработениот <b>{{ notification.workerName }}</b> отиде на пауза поради следната причина: </span>
              <span>{{ notification.message ? notification.message : '/'}}</span>
              <span>{{ showTime(notification.dateCreated) }}</span>
            </div>
          </div>
          <div style="border-bottom: 1px solid #00000030;"></div>
        </div>
      </div>
      <div class="d-flex justify-content-center cursor-pointer py-2 mt-2" (click)="openNotifications()"
        style="background-color: #d3d3d350;">
        <!-- <button class="btn btn-primary mt-2" (click)="openNotifications()">Види ги сите</button> -->
        Види ги сите
      </div>
    </mat-menu>
    <!-- </button> -->

    <button mat-button mat-flat-button class="member-avatar-name ml-8"
      *ngIf="currentActiveAccount && currentActiveAccount.memberId">
      <div fxLayout="row" fxLayoutAlign="center center">

        <img class="avatar-image"
          [src]="currentActiveAccount.image ? currentActiveAccount.image : './../../../../assets/images/empty-avatar.png'">
        <span class="member-first-name" fxHide.xs>{{currentActiveAccount.firstName}}</span>
        <span class="member-first-name" fxHide.xs>{{currentActiveAccount.lastName}}</span>

        <!-- <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item (click)="logout()" style="padding-right: 52px;">
                    <mat-icon style="font-size: 23px !important;">exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu> -->
        <mat-icon style="font-size: 23px !important;position: relative; top: 8px;" matTooltip="Одјави се"
          (click)="logout()">exit_to_app</mat-icon>
      </div>
    </button>
  </div>
</div>
