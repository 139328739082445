import { Injectable } from '@angular/core';
import { Observable, throwError, Observer, of, BehaviorSubject, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Authenticate, LoginObject } from './auth.model';
import { AppService } from '../app.service';
import { Account } from './auth.model';
import { APIResult } from '../shared/shared.model';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthLocalService {
  api_url = environment.API_URL;

  currentActiveAccount: Authenticate = new Authenticate();
  user: Authenticate = new Authenticate();

  /**
   * Constructor
   *
   * @param { CookieService }     _cookie
   * @param { HttpClient }        _httpClient
   * @param { Router }            _router
   * @param { AppService }        _appService
   * @param { SocialAuthService } _socialAuthService
   */
  constructor(private _cookie: CookieService,
              private _httpClient: HttpClient,
              private _router:Router,
              private _appService: AppService,
              private _sharedService: SharedService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to handle error when the server return an error
   *
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }


  /**
	 * Function to store user data
	 */
   private _storeAccountData(data: LoginObject) {
    this.currentActiveAccount = data.member;
    this._sharedService.sidenav_items.next(data.accessiblePages);
    this._cookie.delete('eKartaMemberToken');
    this._cookie.set('eKartaMemberToken', data.loginToken ? data.loginToken : '');
    this._appService.currentActiveAccount = this.currentActiveAccount;
    this._appService.activeUserChanged.next(this.currentActiveAccount);
    // this._router.navigate([data.accessiblePages[0].pageUrl]);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


  /**
	 * Funcion to authenticate user only with token
	 */
  public getUserAuthenticationData(): Observable<LoginObject> {
    if(!this.isTokenHeaderEmpty()){
      console.log('SENDING TOKEN', this.tokenHeader())
      return this._httpClient.get<APIResult<LoginObject>>(this.api_url + 'login/authenticate', { headers:this.tokenHeader() }).pipe(
        map((data: APIResult<LoginObject>) => {
          this._storeAccountData(data.response);
          console.log('STORED TOKEN', data.response.loginToken)
          return data.response;
        }),
        catchError(this.handleError)
      );
    } else {
      this._router.navigate(['auth']);
      return of(new LoginObject());
    }

  }

  /**
	 * Function to POST new login data
	 *
	 * @param { LoginResponse } filter
	 */
  public accountLogin(filter : Account): Observable<LoginObject> {
    return this._httpClient.post<APIResult<LoginObject>>(this.api_url + 'login', filter).pipe(
        map(
          (data: APIResult<LoginObject>) => {
            this._storeAccountData(data.response);
            this._router.navigate([data.response.accessiblePages[0].pageUrl]);
            return data.response;
          }
        ),
        catchError(this.handleError)
      );
  }


  /**
	 * Function for logout
	 */
  logOut() {
    this.currentActiveAccount = new Authenticate();
    this._cookie.delete('eKartaMemberToken');
    this._appService.closeSidenav.next();
    this._router.navigate(['auth']);
  }

  /**
	 * Function to return token header
	 */
  tokenHeader() {
    let $tokenCookie = this._cookie.get('eKartaMemberToken');
    if (typeof $tokenCookie !== 'undefined' && $tokenCookie !== 'undefined' && $tokenCookie !== '') {
      let header = new HttpHeaders({
        'accept'       : 'application/json',
        "Authorization": $tokenCookie
      });
      return header;
    }

    return new HttpHeaders();
  }

  /**
	 * Function to return true for saved token, false for empty token
	 */
  isTokenHeaderEmpty() {
    let $tokenCookie = this._cookie.get('eKartaMemberToken');
    if (typeof $tokenCookie !== 'undefined' && $tokenCookie !== 'undefined' && $tokenCookie !== '') {
      return false;
    }
    return true;
  }

}
