import { Injectable } from '@angular/core';
import { Authenticate } from './auth/auth.model';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  currentActiveAccount: Authenticate = new Authenticate();
  activeUserChanged: BehaviorSubject<Authenticate>;
  closeSidenav: Subject<any> = new Subject();
  user = new Authenticate();

  constructor() {
    this.user.firstName = 'emptyuser'
    this.activeUserChanged = new BehaviorSubject(this.user);
  }

}
