<div class="custom-spinner-wrapper" *ngIf="is_loading_results">
  <mat-spinner strokeWidth="1" [diameter]="65"></mat-spinner>
</div>

<div class="card card-custom">
  <div class="card-header border-0 py-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark">Известувања</span>
    </h3>
  </div>
</div>

<div class="row d-flex justify-content-center mx-0 my-6" style="height: calc(100% - 170px);">
  <div class="col-8 mb-6" style="height: 100%;">
    <div class="card p-4" style="overflow-y: auto; height: 100%; min-height: 100%;"
        infinite-scroll
        [scrollWindow]="false"
        [infiniteScrollDistance]="3"
        [infiniteScrollThrottle]="100"
        (scrolled)="onScrollDown()">
      <div *ngFor="let notification of notificationList">
        <div class="d-flex justify-content-start my-4">
          <img style="width: 50px; height: 50px; border-radius: 50%;" [src]="notification.workerProfilePicture">
          <div class="d-flex flex-column ml-8">
            <span>Вработениот <b>{{ notification.workerName }}</b> отиде на пауза поради следната причина: </span>
            <span>{{ notification.message ? notification.message : '/'}}</span>
            <span>{{ showTime(notification.dateCreated) }}</span>
          </div>
        </div>
        <div style="border-bottom: 1px solid #00000030;"></div>
      </div>
    </div>
  </div>
</div>
